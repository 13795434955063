import { PUBLIC_ENV } from '$env/static/public';
import * as Sentry from '@sentry/svelte';

Sentry.init({
	dsn: 'https://f93a81fdde54b99302ce9d3d27ee734b@o4505280176259072.ingest.us.sentry.io/4507804482338816',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,

	// Capture Replay for 10% of all sessions,
	// plus 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	environment: PUBLIC_ENV
});
